import {ConfigProvider} from "antd";

const DefaultTheme = ({children}: { children: any }) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#1A3B88',
                },
            }}
        >
            {children}
        </ConfigProvider>
    );
}

export default DefaultTheme;