import { Flex } from 'antd'
// import useStyle from '../../hooks/useStyle'
import { useCard } from '../../context/CardContext'
import * as Yup from 'yup'
import { useForm, UseFormRegisterReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputMask } from '@react-input/mask'
import { forwardRef, useId } from 'react'
import VerifyForm from './VerifyForm'
import axios from '../../utils/axios'
import { OtpConfig } from '../../types/otp-config'
// import { number, expirationDate } from 'card-validator'
interface CustomInputProps extends UseFormRegisterReturn {
    label: string
    placeholder: string
    // name: string;
    errorMsg?: string
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
    ({ errorMsg, label, placeholder, ...rest }, forwardedRef) => {
        const id = useId()
        return (
            <Flex vertical gap={'6px'}>
                <label className="card-label" htmlFor={'custom-input' + id}>
                    {label}
                </label>
                <input
                    className="card-input"
                    ref={forwardedRef}
                    placeholder={placeholder}
                    id={'custom-input' + id}
                    {...rest}
                />
                {errorMsg && (
                    <span
                        style={{ fontSize: '12px', color: 'crimson' }}
                        id="standard-weight-helper-text-login"
                    >
                        {errorMsg}
                    </span>
                )}
            </Flex>
        )
    }
)

const CardForm = ({ setStep }: { setStep: any }) => {
    // const { styles } = useStyle()
    const { saveOtpConfig } = useCard()

    const yupSchema = Yup.object().shape({
        cardNumber: Yup.string()
            // .test('is-card-valid', 'Karta raqami noto\'gri', (value) => {
            //     return number(value || '').isValid
            // })
            .required('Karta raqamini kiritish majburiy')
            .min(19, "Karta raqamini to'liq kiriting"),
        expiry: Yup.string()
            // .test(
            //     'is-date-valid',
            //     'Amal qilish muddati xato',
            //     (value: string) => {
            //         return expirationDate(value || '').isValid
            //     }
            // )
            .required('Amal qilish muddatini kiritish Majburiy')
            .min(5, "Amal qilish muddatini to'liq kiriting"),
    })

    const {
        register,
        handleSubmit,
        // watch,
        formState: { errors },
        setError,
    } = useForm({
        defaultValues: { cardNumber: '', expiry: '' },
        resolver: yupResolver(yupSchema),
    })
    // console.log({
    //     errors,
    //     card: watch('cardNumber'),
    //     expiry: watch('expiry'),
    //     validNum: number(watch('cardNumber')),
    //     validDate: expirationDate(watch('expiry')),
    // })
    const onSubmit = (data: { cardNumber: string; expiry: string }) => {
        // console.log('Submitted Data:', data)
        const number = data.cardNumber.replaceAll(' ', '')
        // setStep(VerifyForm.name)
        axios
            .post('/card/add', { number, expiry: data.expiry.replace('/', '') })
            .then((res) => {
                const options: OtpConfig = {
                    id: res.headers['otp-id'],
                    source: res.headers['otp-source'],
                }
                saveOtpConfig(options)
            })
            .then(() => {
                setStep(VerifyForm.name)
            })
            .catch((err) => {
                console.log(err.body?.message)
                setError('cardNumber', { message: err?.body?.message })
            })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Karta ma'lumotlari</h2>
            <Flex gap={'12px'} vertical>
                <InputMask
                    mask="____ ____ ____ ____"
                    component={CustomInput}
                    errorMsg={errors.cardNumber?.message}
                    placeholder="1234 5678 9012 3456"
                    label="Karta raqami"
                    replacement={{ _: /\d/ }}
                    name="cardNumber"
                    {...register('cardNumber')}
                />
                <InputMask
                    mask="__/__"
                    component={CustomInput}
                    errorMsg={errors.expiry?.message}
                    placeholder="00/00"
                    label="Amal qilish muddati"
                    replacement={{ _: /\d/ }}
                    name="expiry"
                    {...register('expiry')}
                />
                <button className="submit-btn" type="submit">
                    Qo'shish
                </button>
            </Flex>

            <p className="payme-note">
                Sizning kartangiz ma'lumotlari ushbu servisda emas, balki
                bevosita{' '}
                <a
                    className="payme-link"
                    href="https://cdn.payme.uz/terms/main.html?target=_blank"
                >
                    Payme{' '}
                </a>
                serverida saqlanadi
            </p>
        </form>
    )
}

export default CardForm
