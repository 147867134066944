import { createContext, useContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { OtpConfig } from "../types/otp-config";

export type AuthType = {
    isLoggedIn: boolean;
}

export const CardContext = createContext<{ otpConfig: OtpConfig, saveOtpConfig: (config: OtpConfig) => void }>({ otpConfig: {}, saveOtpConfig: (config: OtpConfig) => { } })

export const CardProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [otpConfig, setOtpConfig] = useLocalStorage<OtpConfig>('otp-config', { id: undefined, source: undefined })

    const saveOtpConfig = (config: OtpConfig) => {
        setOtpConfig(config)
    }
    return (
        <CardContext.Provider value={{ otpConfig, saveOtpConfig }}>
            {children}
        </CardContext.Provider>
    )
}

export const useCard = () => useContext(CardContext);