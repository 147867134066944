import axios from 'axios';

const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3010/',
    // baseURL: '/api',
    withCredentials: true,
    headers: {
        'X-Bot-Id': '1',
        'Accept-Language': 'en-US'
    }
});

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
    (response) => {
        console.log({ response });
        return response;
    },
    (error) => {
        if (error.response?.status === 401 && window.location.href !== '/') {
            window.location.pathname = '/';
        }
        return Promise.reject((error.response && error.response.data) || 'Wrong Services');
    }
);

axiosServices.interceptors.request.use((request) => {
    console.log({ request });
    return request;
});

export default axiosServices;
