import { Navigate, Route, Routes } from "react-router-dom"
import AddCard from "./pages/AddCard";
import { TelegramProvider } from "./context/TelegramContext";
import { CardProvider } from "./context/CardContext";
// import VerifyForm from "./pages/add-card/VerifyForm";
// import { useVerification } from "./hooks/useVerification";

export const App = () => {

    return (
        <TelegramProvider>
            <CardProvider>
                <Routes>
                    <Route path='*' element={<Navigate to={'/1/card/add-card'} />} />
                    {/* <Route
                path='/'
                index
                element={
                    <div className="form-container">
                        <VerifyForm handleSubmit={handleVerification} />
                    </div>
                }
            /> */}
                    <Route path="/:bot_id/card/add-card" element={<AddCard />} />
                </Routes>
            </CardProvider>
        </TelegramProvider>
    )
}