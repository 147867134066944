import React, { useState } from 'react'
// { useState }
import { Button, ConfigProvider, Input, Form, Flex, notification } from 'antd'
import OtpInput from 'react-otp-input'
// import { CardFormData, VerifyCode } from "../../types";
import useStyle from '../../hooks/useStyle'
import { useTelegram } from '../../context/TelegramContext'
import { useCard } from '../../context/CardContext'
import axios from '../../utils/axios'

interface VerifyFormProps {
    otpLength?: number
}

const VerifyForm: React.FC<VerifyFormProps> = (props) => {
    const { otpLength = 6 } = props
    const [api, contextHolder] = notification.useNotification()
    const [otp, setOtp] = useState('')
    const [error, setError] = useState('')

    const openNotification = () => {
        api.success({
            message: `Muvaffaqiyatli`,
            description: 'Sizning kartangiz muvaffaqiyatli ulandi!',
            placement: 'bottom',
            duration: 2.9,
        })
    }

    const { webApp } = useTelegram()
    const { otpConfig } = useCard()

    const handleSubmit = (data: { code: string }) => {
        axios
            .post('/card/activate', { code: data.code, otp_id: otpConfig.id })
            .then((res) => {})
            .then(() => {
                openNotification()
                // setStep(CardForm.name);
                // console.log('succes closed')
                setTimeout(() => {
                    webApp.close()
                }, 3)
            })
            .catch((err) => {
                setError(err?.body?.message ?? '')
            })
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (otp.length < 6) {
            setError("Iltimos, kodni to'liq kiriting!")
        } else {
            handleSubmit({ code: otp })
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <h2>Kod</h2>
            <Flex vertical align="center" gap={'4px'}>
                <Flex vertical>
                    <OtpInput
                        value={otp}
                        onChange={(value) => {
                            setOtp(value)
                            if (error) {
                                setError('')
                            }
                        }}
                        numInputs={otpLength}
                        inputType="number"
                        containerStyle={{ gap: '6px' }}
                        placeholder="******"
                        renderInput={(props) => (
                            <input
                                {...props}
                                className={
                                    props.className +
                                    ' otp-input' +
                                    `${error && ' error'}`
                                }
                            />
                        )}
                    />
                    <span
                        style={{
                            visibility: error ? 'visible' : 'hidden',
                            opacity: error ? 1 : 0,
                        }}
                        className={`errorMsg`}
                        id="standard-weight-helper-text-login"
                    >
                        {error}
                    </span>
                </Flex>
            </Flex>

            <button
                style={{ marginTop: 12 }}
                className="submit-btn"
                type="submit"
            >
                Yuborish
            </button>
            {contextHolder}
        </form>
    )
}

export default VerifyForm
