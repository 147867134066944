import CardForm from './add-card/CardForm'
import { useState } from 'react'
import VerifyForm from './add-card/VerifyForm'

const AddCard = () => {
    const [step, setStep] = useState(CardForm.name)

    const renderStep = () => {
        switch (step) {
            case CardForm.name:
                return <CardForm setStep={setStep}></CardForm>
            case VerifyForm.name:
                return <VerifyForm />
        }
    }

    return (
        <div className="form-container">
            {renderStep()}

            {/* <button onClick={openNotification}>notify</button> */}
        </div>
    )
}

export default AddCard
