import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ITelegramUser, IWebApp } from "../types/telegram";
import axios from "../utils/axios";
import { useLocation } from "react-router-dom";

export interface ITelegramContext {
    webApp?: IWebApp;
    user?: ITelegramUser;
}

export const TelegramContext = createContext<ITelegramContext>({});

export const TelegramProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const location = useLocation()
    console.log({ location })
    const [webApp, setWebApp] = useState<IWebApp | null>(null);
    const authorizeUser = async (userId: string) => {
        return await axios.post('/auth/auth/', { user_id: userId })
    }

    useEffect(() => {
        const botId = location.pathname.split('/')[1] ?? ''
        console.log({ botId });

        if (botId) {
            axios.defaults.headers.common['X-Bot-Id'] = botId
        }
    }, [location.pathname])

    useEffect(() => {
        const app = (window as any).Telegram?.WebApp;

        if (app) {
            app.ready();
            setWebApp(app);
            authorizeUser(app?.initDataUnsafe?.user?.id || "1985764946").catch((err) => { console.log(err) })
        }
    }, []);

    const value = useMemo(() => {
        return webApp
            ? {
                webApp,
                unsafeData: webApp.initDataUnsafe,
                user: webApp.initDataUnsafe.user,
            }
            : {};
    }, [webApp]);

    return (
        <TelegramContext.Provider value={value}>
            {children}
        </TelegramContext.Provider>
    );
};

export const useTelegram = () => useContext(TelegramContext);