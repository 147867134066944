import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/styles.css';
import DefaultTheme from "./components/DefaultTheme";
import { BrowserRouter } from "react-router-dom";
import { App } from './App';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <DefaultTheme>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </DefaultTheme>
);
